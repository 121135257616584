import * as React from "react"
import BaseLayout from "../components/BaseLayout";
import Profile from '../components/Authentication/Profile.jsx'
import LogoutButton from "../components/Authentication/LogoutButton";
import LoginButton from "../components/Authentication/LoginButton";
import { Link } from "gatsby";
import { useAuth0 } from "@auth0/auth0-react";


const AccountPage = () => {
  const {isAuthenticated} = useAuth0();
  return (
  <BaseLayout>
    <h1>Account</h1>
    {process.env.BRANCH && <h2>On:{process.env.BRANCH}</h2>}
    <br/>
    {isAuthenticated ? <LogoutButton/> : <LoginButton/>}
    <br/>
    <Link to="/">Home</Link><br/>
    <hr/>
    <ul>
    <li><Link to="http://localhost:8000/login">Login: Localhost</Link></li>
    <li><Link to="https://foobarbaz.link/login">Login: FooBarBaz.link</Link></li>
    <li><Link to="https://staging--armstrong-netlify-poc.netlify.app/login">Login: Staging POC</Link></li>
    <li><Link to="https://armstrong-netlify-poc.netlify.app/login">Login: Prod Netlify Url</Link></li>
    </ul>
    <hr/>
    <br/>
    <Profile details={true}/>
  </BaseLayout>
)
}

export default AccountPage


